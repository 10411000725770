import React, { useEffect, useState } from "react";
import useScrollPosition from "../../../hooks/useScrollPosition/useScrollPosition";
import "./HomeDecorations.scss";

const HomeDecorations = () => {
  const [presentationStep, setPresentationStep] = useState(0);
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    setPresentationStep(scrollPosition);
  }, [scrollPosition]);

  return (
    <div className="homeDecorations">
      <div className="d1"></div>
      <div className="d3"></div>
      <div className="d4"></div>
      <div className="d6"></div>
      <div className="d7"></div>
      <div className="d8"></div>
      <div className="d11"></div>
      <div className="d12"></div>
      <div className="d14"></div>
      <div className="d15"></div>
      <div className="d16"></div>
      <div className="d17"></div>
      <div className="d21"></div>
      <div className="d22"></div>

      <div className="d23"></div>
      <div className="d24"></div>
      <div className="d26"></div>
      <div className="d27"></div>
    </div>
  );
};

export default HomeDecorations;
