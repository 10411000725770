import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import DocumentsModalContent from "./DocumentsModalContent";
import { getFormErrorMsg } from "../../../helpers/formErrorMessages";
import { useFormSubmitStatus } from "../../../hooks";
// import axios from "axios";
import {
  patriotActContent,
  TCPAContent,
  // termsAndConditionsContent,
  applicationAndSolicitationDisclosureContent,
  EDASCContent,
} from "./DocumentsContent";

const DocumentsCheckboxField = ({
  disabled,
  name,
  defaultValue,
  id,
  label,
  labelAbove,
  containerClassName,
  customErrorMsg = "You must agree to the terms above",
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [hasAgreed, setHasAgreed] = useState();
  const submitting = useFormSubmitStatus();

  const {
    register,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
  } = useFormContext();

  useEffect(() => {
    setValue(name, hasAgreed);

    if (hasAgreed) {
      clearErrors(name);
    }

    if (hasAgreed === false) {
      setError(name, {
        type: "custom",
        message: customErrorMsg,
      });
    }
  }, [hasAgreed]);

  return (
    <>
      <div
        className={`input-container full-width ${containerClassName} ${
          Boolean(getFormErrorMsg(errors, name)) && "error"
        } checkbox ${disabled ? " disabled" : ""}`}
      >
        {labelAbove && (
          <label htmlFor={id} className="label-above">
            {labelAbove}
          </label>
        )}
        <input
          disabled={disabled || submitting}
          {...register(name)}
          {...rest}
          checked={hasAgreed}
          onChange={() => (!hasAgreed ? setOpen(true) : setHasAgreed(false))}
          id={id}
          type={"checkbox"}
          onWheel={(event) => event.target.blur()}
        />
        {label && <label htmlFor={id}>{label}</label>}
      </div>
      {Boolean(getFormErrorMsg(errors, name)) && (
        <div
          className="error-hint"
          style={{ color: "red", marginLeft: "2rem" }}
        >
          {getFormErrorMsg(errors, name)}
        </div>
      )}

      <Modal
        open={open}
        onClose={() => {
          setHasAgreed(false);
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <DocumentsModalContent
          handleClose={() => setOpen(false)}
          handleAccept={() => setHasAgreed(true)}
          documents={[
            // { id: 1, title: "Credit Authorisation", text: randomContent },

            {
              id: "electronic-documents",
              title: "Electronic Documents and Signature Consents",
              text: EDASCContent,
            },
            {
              id: "telephone-consumer-protection",
              title: "Telephone Consumer Protection Act Consent",
              text: TCPAContent,
            },
            {
              id: "patriot-act",
              title: "Patriot Act Notice",
              text: patriotActContent,
            },
            {
              id: "application-disclosure",
              title: "Application & Solicitation Disclosure",
              text: applicationAndSolicitationDisclosureContent,
            },
            // {
            //   id: 5,
            //   title: "Terms & Conditions",
            //   text: termsAndConditionsContent,
            // },
          ]}
        />
      </Modal>
    </>
  );
};

export default DocumentsCheckboxField;
