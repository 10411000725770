import React from "react";
import useGetBlogRelatedArticles from "../../hooks/useGetBlogRelatedArticles/useGetBlogRelatedArticles";
import CustomCard from "../../components/common/card/Card";
import "./BlogRelatedArticles.scss";
import { useParams } from "react-router-dom";

const BlogRelatedArticle = () => {
  const { slug } = useParams();
  const { data } = useGetBlogRelatedArticles({slug});

  if (!data) {
    return null;
  }

  return (
    <section className="section__innerWrapper">
      <div className="related-articles">
        <h2 className="related-articles__section-title">Related Articles</h2>
        <div className="related-articles__container">
          {data?.map((article) => (
            <CustomCard key={article.id} data={article} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogRelatedArticle;

