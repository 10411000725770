import React, { useEffect, useState } from "react";
import { getQuestionnaireURL } from "../../../api/funding-application";
import { Loader } from "../../../components/common";
import { useGetMe, useWindowSize } from "../../../hooks";
import { FileUploadField } from "../../../components/common";

const PsychTest = ({ onProceed }) => {
  const [testUrl, setTestUrl] = useState();
  const { me: data, refetch } = useGetMe();
  const url = new URL(
    `${process.env.REACT_APP_MERCURE_URL}/.well-known/mercure`
  );
  url.searchParams.append("topic", "questionnaire-updates");

  const [isMobile, setIsMobile] = useState(false);

  const windowSize = useWindowSize();

  // const eventSource = new EventSource(url);
  // eventSource.onmessage = (e) => {
  //   console.log(123, e);
  //   onProceed("stepFourFinished");
  // };

  useEffect(() => {
    (async () => {
      await getQuestionnaireURL().then((res) => {
        setTestUrl(res?.data?.["questionnaire-url"]);
      });
    })();

    setInterval(() => {
      refetch().then(({ data }) => {
        const applicationState =
          data?.data?.fundingApplications?.[0]?.currentState;
        if (applicationState === "stepFourFinished") {
          onProceed("stepFourFinished");

          // Track the event
          window.gtag("event", "submitted_second_psych", {
            event_category: "Form Submission",
            event_label: "Completed Psych Test",
            value: 1,
          });

          window.ttq.track('submitted_second_psych');
        }
      });
    }, 5000);
  }, []);

  useEffect(() => {
    document.body.classList.add("p-test");
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });

    return () => {
      document.body.classList.remove("p-test");
    };
  }, []);

  useEffect(() => {
    setIsMobile(checkIsMobile());
  }, [windowSize]);

  const checkIsMobile = () => {
    const mobileBreakpoint = 768;
    return windowSize.width <= mobileBreakpoint;
  };

  return (
    <div>
      {!testUrl ? (
        <Loader style={{ height: "calc(100vh - 320px)" }} />
      ) : (
        <iframe
          src={testUrl}
          style={
            isMobile
              ? { height: "calc(100vh - 160px)", width: "100%", border: "none" }
              : {
                  height: "calc(100vh - 320px)",
                  minHeight: "700px",
                  width: "100%",
                  border: "none",
                }
          }
        ></iframe>
      )}
      <div className="flex justify-center align-center">
        <div>
          Note: You will be redirected automatically after you finish the survey.
        </div>
      </div>
    </div>
  );
};

export default PsychTest;
