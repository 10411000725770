import React, {useEffect, useState} from "react";
import { object, string } from "yup";
import { updateFundingApplication } from "../../../api/funding-application";
import {
  Button,
  FileUploadField,
  Form,
  SSNInputField,
} from "../../../components/common";
import {useCurrentFundingApplication} from "../../../hooks";
import "./Documents.scss";

const validationSchema = object({
  upload1: string().required("This is a required field"),
  upload2: string().required("This is a required field"),
  // upload3: string().required("This is a required field"),
  socialSecurityNumber: string()
    .required("This is a required field")
    .test("isSSNValid", "This is not a valid social security number", (v) => {
      if(!v) return false;

      console.log("v: ", v.replaceAll("-", "").replaceAll(/\s/g, ""));
      return v.replaceAll("-", "").replaceAll(/\s/g, "").length === 9;
    }),
  // agreement: boolean().isTrue("You must agree to the terms above"),
});

const Documents = ({ onProceed, isReadonly = false }) => {
  const [ currentApplication, fundingApplicationId ] = useCurrentFundingApplication();
  const [readonlyData, setReadonlyData] = useState({});

  const handleSubmit = async (data) => {
    // if is readonly mode - just change view
    if (isReadonly) {
      onProceed("terminated");
      return;
    }

    const { socialSecurityNumber, upload1, upload2 } = data;

    let submitData = {
      transition: "to_terminated",
      socialSecurityNumber,
      uploads: [upload1, upload2],
    };

    try {
      await updateFundingApplication({
        data: submitData,
        id: fundingApplicationId,
      }).then(() => {
        onProceed("terminated");

        localStorage.setItem("old_dashboard", true);
      });
    } catch (e) {
      console.log("Error: ", e);
    }

    // Track the event
    window.gtag("event", "submitted_finish", {
      event_category: "Form Submission",
      event_label: "Completed Documents Form",
      value: 1,
    });

    window.ttq.track('submitted_finish');
  };
  useEffect(() => {
    document.body.classList.add("documents");
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });

    return () => {
      document.body.classList.remove("documents");
    };
  }, []);

  useEffect(() => {
    if (!isReadonly) return;

    setReadonlyData({
      'socialSecurityNumber': { defaultValue: currentApplication?.socialSecurityNumber, isReadonly: true },
      'identity': { defaultValue: currentApplication?.uploads?.filter(file => file.type === 'TYPE_ID')[0], isReadonly: true },
      'transcript': { defaultValue: currentApplication?.uploads?.filter(file => file.type === 'TYPE_TRANSCRIPT')[0], isReadonly: true },
    });


  }, [isReadonly, currentApplication]);

  return (
    <div className="step-wrapper personal-info flex-column align-center step--documents">
      <h1>Last steps, promise! Upload documents and sign</h1>
      <div className="text-center">
        {/* Let us create you a profile so we can save
        <br />
        the information for you. */}
      </div>
      <Form onSubmit={handleSubmit} validationSchema={validationSchema}>
        <div className="flex-column gap-3rem">
          <SSNInputField
            id="socialSecurityNumber"
            name="socialSecurityNumber"
            placeholder="XXX-XX-XXXX"
            labelAbove="Social Security Number"
            {...readonlyData.socialSecurityNumber}
            required
          />
          <FileUploadField
            id="upload1"
            name="upload1"
            title={"Verify Identity"}
            uploadLabel={
              "Please download a copy of your ID (driver license, passport or State issued ID)"
            }
            helperText={"Supported formats: .jpeg, .png, .pdf"}
            allowedTypes={[".jpeg", ".png", "pdf"]}
            fileUploadType={"TYPE_ID"}
            {...readonlyData.identity}
            required
          />
          <FileUploadField
            id="upload2"
            name="upload2"
            labelAbove={"Download Transcript"}
            helperText={"Supported formats: .jpeg, .png, .pdf"}
            allowedTypes={[".jpeg", ".png", "pdf"]}
            title={"Download Transcript"}
            uploadLabel={"Upload your most recent unofficial transcript"}
            fileUploadType={"TYPE_TRANSCRIPT"}
            {...readonlyData.identity}
            required
          />
          {/* <div>
            <FileDownload
              title={"Review and Sign ISA agreement"}
              fileName={"ISA agreement.pdf"}
              fileUrl={"https://www.irs.gov/pub/irs-pdf/f4506t.pdf"}
              required
            />
            <FileUploadField
              id="upload3"
              name="upload3"
              labelAbove={"Upload most recent transcript"}
              helperText={"Supported formats: .jpeg, .png, .pdf"}
              allowedTypes={[".jpeg", ".png", "pdf"]}
              uploadLabel={
                "Download the file pdf file below and upload an image of the signed document"
              }
              fileUploadType={"TYPE_ISA"}
            />
          </div> */}
          {/* <DocumentsCheckboxField
            id="agreement"
            name="agreement"
            label={
              <div>
                <div>
                  By checking this box, I expressly consent to YELO Funding
                  contacting me using the information I provide, in accordance
                  with their TCPA Consent
                </div>
                <div>Privacy Policy for Consumer Financial Information.</div>
                <div>Electronic Documents and Signature Consent.</div>
              </div>
            }
            required
          /> */}
          <Button
            className="primary full-width xl"
            type="submit"
            label="Finish application"
            onClick={() => {
              if (isReadonly) {
                handleSubmit()

                return false;
              }

              return true;
            }}
          />
        </div>
      </Form>
    </div>
  );
};

export default Documents;
