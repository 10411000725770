import React, { useMemo } from "react";
import { currencyFormatter } from "../../../helpers/currencyFormat";

const Calculations = ({
  apr,
  maxAPR,
  effectiveTerm,
  // firstMonthPayment,
  // maxMonthlyPayment,
  averageMonthlyPayment,
  totalCosts,
  totalPayments = [],
}) => {
  const maxYearlyPayment = Math.max(...totalPayments);

  const APR = useMemo(() => {
    let val = (apr * 100 <= 9.9 ? apr * 100 : maxAPR * 100).toFixed(2);
    return val < 0 ? "-" : `${val}%`;
  }, [apr, maxAPR]);

  return (
    <>
      <div className="half">
        {/* <h4>Income Share Agreements</h4> */}
        <ul>
          <li>
            <span>Total Costs</span>
            <span>
              {totalCosts < 0
                ? currencyFormatter(0)
                : currencyFormatter(totalCosts)}
            </span>
          </li>
          <li>
            <span>Effective Term</span>
            <span>{effectiveTerm} months</span>
          </li>
          {/* <li>
            <span>1st Monthly Payment</span>
            <span>{currencyFormatter(firstMonthPayment, 2)}</span>
          </li>
          <li>
            <span>Max Monthly Payment</span>
            <span>{currencyFormatter(maxMonthlyPayment, 2)}</span>
          </li> */}
          <li>
            <span>Average Monthly Payment</span>
            <span>{currencyFormatter(averageMonthlyPayment, 2)}</span>
          </li>
          <li>
            <span>APR</span>
            <span>{APR}</span>
          </li>
        </ul>
      </div>

      <div className="full" style={{ marginTop: "2.4rem" }}>
        {/* <h5>Monthly Payments</h5>
        <div className="graphic">
          <div className="graphic__sum">
            <ul>
              <li>
                <span>{Math.ceil((25 * maxYearlyPayment) / 12 / 100)}</span>
              </li>
              <li>
                <span>{Math.ceil((50 * maxYearlyPayment) / 12 / 100)}</span>
              </li>
              <li>
                <span>{Math.ceil((75 * maxYearlyPayment) / 12 / 100)}</span>
              </li>
              <li>
                <span>{Math.ceil(maxYearlyPayment / 12)}</span>
              </li>
            </ul>
          </div>
          <div className="graphic__years">
            <ul>
              {totalPayments.map((payment) => {
                return (
                  <li>
                    <span
                      className="payment payment--yelo"
                      style={{
                        height:
                          payment > 0
                            ? `${(payment / maxYearlyPayment) * 100 - 8}%`
                            : "0%",
                      }}
                    ></span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div> */}
        <small>
        Assumes that earned income remains constant. Total Costs are in addition to the funding amount received. Assumes YELO withholds from salary at a rate of 0.05% for every thousand funded. Does not assume any payment pauses. ICF terms: 2% Disbursement Fee; 10-year max; Repayment cap of 1.90x funding amount. Minimum Income Threshold: $30,000. Payments start 12 months after In-School Period and 6 months after Grace Period. You can refer to the United States Department of Education College Scorecard for salary estimates related to your field of study at
          {" "}
          <a
            href="https://collegescorecard.ed.gov"
            title="https://collegescorecard.ed.gov"
            target="_blank"
          >
            https://collegescorecard.ed.gov
          </a>
          .
        </small>
      </div>
    </>
  );
};

export default Calculations;
