import React, { useEffect, useRef, useState } from "react";
import { useIsInViewport, useWindowSize } from "../../../hooks";
import "./Fundings.scss";

const Fundings = ({ title, subtitle, fileName, bubbles }) => {
  const windowSize = useWindowSize();
  const isInViewportThreshold = 0;//!isMobile() ? 0.5 : 0; // value of 0 fixes the mobile

  const sectionRef = useRef(null);
  const imgRef = useRef(null);
  const isInViewport = useIsInViewport(sectionRef, isInViewportThreshold);
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    if (isInViewport) {
      setTimeout(() => setShowAnimation(isInViewport), 50);
    }
  }, [isInViewport]);

  function isMobile() {
    const mobileBreakpoint = 768;
    return windowSize.width <= mobileBreakpoint;
  }

  return (
    <section
      className={`section section--ourFundings${
        showAnimation ? " onScreen" : ""
      }`}
      ref={sectionRef}
    >
      <div className="section__innerWrapper">
        <h2 className="center animateIn">
          <a className="anchor" name="how-it-works">
          HOW IT WORKS
          </a>
        </h2>
        <h3 className="center animateIn">{title}</h3>
        <h4 className="center animateIn subtitle">{subtitle}</h4>
        <ol>
          {bubbles?.map((bubble, i) => (
            <li
              key={i}
              className="animateIn"
            >
              {bubble.short}
            </li>
          ))}
        </ol>
        <img
          className="content animateIn"
          src={`${process.env.REACT_APP_IMG_URL}/${fileName}`}
          alt=""
          ref={imgRef}
        />
        <a href="#apply-now" title="Apply now" className="apply-now-button">
          <button className="button primary">
            Apply now
          </button>
        </a>
      </div>
    </section>
  );
};

export default Fundings;
