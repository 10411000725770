import React, {useEffect, useState} from "react";
import { boolean, number, object, string } from "yup";
import { updateFundingApplication } from "../../../api/funding-application";
import {
  Button,
  DatePickerField,
  DropdownField,
  Form,
  InputField,
} from "../../../components/common";
import {useCurrentFundingApplication} from "../../../hooks";

import "./AdditionalInfo.scss";
import { CurrencyInputField } from "../../../components/common/input/CurrencyInput";

// const testData = {
//   transition: "to_stepThreeFinished",
//   gpa: 25.0,
//   numberCurricularActivities: 3,
//   numberInternships: 2,
//   birthDate: "1984-09-27",
//   address: "30, 150th Avenue New York 44543 - USA",
//   numberOtherLoans: 0,
//   // otherStudentLoansAmount: 1200,
//   otherISAOutstandingAmount: 1500,
//   consideringFurtherEducation: false,
//   linkedInUrl: "https://linkedIn.com",
// };

const validationSchema = object({
  gpa: number("Please enter a valid GPA").required("GPA is a required field"),
  numberCurricularActivities: number("Please enter a valid number").required(
    "This is a required field"
  ),
  numberInternships: number("Please enter a valid number").required(
    "This is a required field"
  ),
  birthDate: string().required("BOD is a required field"),
  address: string().required("Address is a required field"),
  // numberOtherLoans: number("Please enter a valid number").required(
  //   "This is a required field"
  // ),
  otherStudentLoansAmount: number().required("This is a required field"),
  otherISAOutstandingAmount: number().required("This is a required field"),
  consideringFurtherEducation: boolean().required("This is a required field"),
  linkedInUrl: string(),
});

// WORKFLOW STEP 3
const AdditionalInfo = ({ onProceed, isReadonly = false }) => {
  const [ currentApplication, fundingApplicationId, refetch ] = useCurrentFundingApplication();
  const [readonlyData, setReadonlyData] = useState(currentApplication);

  const handleSubmit = async (data) => {
    // if is readonly mode - just change view
    if (isReadonly) {
      onProceed((currentApplication?.currentState !== 'stepThreeFinished' ? 'stepFourFinished' : 'stepThreeFinished'));
      return;
    }

    try {
      await updateFundingApplication({
        data: { ...data, transition: "to_stepThreeFinished" },
        id: fundingApplicationId,
      }).then(async (res) => {
        onProceed("stepThreeFinished");
      });
    } catch (e) {
      console.log("Error: ", e);
    }

    // Track the event
    window.gtag("event", "submitted_first_finish", {
      event_category: "Form Submission",
      event_label: "Submitted User Additional Info Form",
      value: 1,
    });

    window.ttq.track('submitted_first_finish');
  };

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    document.body.classList.add("additional-info");
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });

    return () => {
      document.body.classList.remove("additional-info");
    };
  }, []);


  let defaultAttributes = {};
  if (isReadonly) {
    defaultAttributes = {
      'address': { value: currentApplication?.address, readOnly: true },
      'gpa' : { value: currentApplication?.gpa, readOnly: true },
      'numberCurricularActivities': { defaultValue: currentApplication?.numberCurricularActivities, readOnly: true },
      'numberInternships': { defaultValue: currentApplication?.numberInternships, readOnly: true },
      'otherStudentLoansAmount': { defaultValue: currentApplication?.otherStudentLoansAmount, readOnly: true },
      'otherISAOutstandingAmount': { defaultValue: currentApplication?.otherISAOutstandingAmount, readOnly: true },
      'consideringFurtherEducation': { defaultValue: currentApplication?.consideringFurtherEducation ? 'Yes' : 'No', readOnly: true },
      'linkedInUrl': { value: currentApplication?.linkedInUrl, readOnly: true },
    }

    const dateObject = new Date(currentApplication?.birthDate);
    if (currentApplication?.birthDate && dateObject) {
      const formattedDate = dateObject.toISOString().split('T')[0];
      defaultAttributes.birthDate = { value: formattedDate, readOnly: true };
    }
  }

  return (
    <div className="step-wrapper personal-info flex-column align-center">
      <h1 style={{ fontSize: "24px", fontWeight: 400 }}>
        We need some additional information from you
      </h1>
      {/* <h2
        className="text-center"
        style={{ fontSize: "16px", fontWeight: 400, marginBottom: "2rem" }}
      >
        Let us create you a profile so we can save the information for you.
      </h2> */}
      <Form onSubmit={handleSubmit} validationSchema={validationSchema}>
        <InputField
          id="address"
          name="address"
          labelAbove={"Mailing Address"}
          placeholder={"Input Mailing Address"}
          {...defaultAttributes?.address}
          required
        />
        <DatePickerField
          id="birthDate"
          name="birthDate"
          labelAbove={"Date of birth"}
          placeholder={"Input DOB"}
          {...defaultAttributes?.birthDate}
          required
        />
        <InputField
          id="gpa"
          name="gpa"
          labelAbove={"GPA"}
          placeholder={"E.g. 3.00"}
          {...defaultAttributes?.gpa}
          required
        />
        <DropdownField
          id="numberCurricularActivities"
          name="numberCurricularActivities"
          labelAbove={
            "Number of clubs and extra-curricular activities you have been involved in college:"
          }
          placeholder={"Select"}
          options={[
            { label: "0", value: 0 },
            { label: "1", value: 1 },
            { label: "2", value: 2 },
            { label: "3", value: 3 },
            { label: "4", value: 4 },
            { label: "5", value: 5 },
            { label: "6", value: 6 },
            { label: "7", value: 7 },
            { label: "8", value: 8 },
            { label: "9", value: 9 },
            { label: "10+", value: 10 },
          ]}
          dropdownLabelField="label"
          dropdownValueField="value"
          {...defaultAttributes?.numberCurricularActivities}
          required
        />
        <DropdownField
          id="numberInternships"
          name="numberInternships"
          type="number"
          labelAbove={
            "Number of internships related to your field of study you have done:"
          }
          placeholder={"Select"}
          options={[
            { label: "0", value: 0 },
            { label: "1", value: 1 },
            { label: "2", value: 2 },
            { label: "3", value: 3 },
            { label: "4", value: 4 },
            { label: "5", value: 5 },
          ]}
          dropdownLabelField="label"
          dropdownValueField="value"
          {...defaultAttributes?.numberInternships}
          required
        />
        {/* <InputField
          id="numberOtherLoans"
          name="numberOtherLoans"
          type="number"
          labelAbove={"Amount of other student loans and ISA"}
          placeholder={"Input amount"}
          required
        /> */}

        <CurrencyInputField
          id="otherStudentLoansAmount"
          name="otherStudentLoansAmount"
          labelAbove={"Amount of other student loans outstanding"}
          placeholder={"Input amount"}
          {...defaultAttributes?.otherStudentLoansAmount}
          required
        />
        <CurrencyInputField
          id="otherISAOutstandingAmount"
          name="otherISAOutstandingAmount"
          labelAbove={"Amount of other ISAs outstanding"}
          placeholder={"Input amount"}
            {...defaultAttributes?.otherISAOutstandingAmount}
          required
        />
        <DropdownField
          id="consideringFurtherEducation"
          name="consideringFurtherEducation"
          labelAbove={
            "Are you considering further education or training upon graduation?"
          }
          placeholder={"Select"}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
          dropdownLabelField="label"
          dropdownValueField="value"
            {...defaultAttributes?.consideringFurtherEducation}
          required
        />
        <InputField
          id="linkedInUrl"
          name="linkedInUrl"
          labelAbove={"LinkedIn Profile"}
          placeholder={"Input URL"}
          {...defaultAttributes?.linkedInUrl}
          required
        />
        <small>
          At this point, we ask all of our students to assist us by completing a
          brief customer survey. This survey will help us to get to know you
          better and will not affect your decision.
        </small>
        <Button
          className="primary full-width xl"
          type="submit"
          label="Continue"
          onClick={() => {
            if (isReadonly) {
              handleSubmit()

              return false;
            }

            return true;
          }}
        />
        {/* <Button
          className="secondary full-width xl"
          label="Continue (with test data & skip validation)"
          onClick={() => handleSubmit(testData)}
        /> */}
      </Form>
    </div>
  );
};

export default AdditionalInfo;
