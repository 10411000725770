import React, { useMemo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Guard, NavBar } from "../components";
import { Loader } from "../components/common";
import { useGetHomePageContent } from "../hooks";
import {
  Dashboard,
  FundingApplication,
  FundingApplicationStepper,
  Home,
  Login,
  Verification,
  Blog,
  BlogArticle,
} from "../pages";
import Cookies from "../pages/cookies/Cookies";
import PrivacyPolicyDownloadPage from "../pages/downloads/PrivacyPolicy";
import TCPAConsentDownloadPage from "../pages/downloads/TCPA";
import PasswordResetPage from "../pages/authorization/login/password-reset/PasswordReset";
import NewPasswordPage from "../pages/authorization/login/password-reset/NewPassword";

const AppRoutes = () => {
  let { data: content, isLoading } = useGetHomePageContent();
  content = { contactUsPhone: content?.phone, ...content }; // Fix - keep variable name for phone consistent with contactUs variables

  const routes = useMemo(() => {
    return [
      {
        key: "home",
        name: "Home",
        path: "/",
        element: isLoading ? <Loader /> : <Home {...content} />,
      },
      {
        key: "login",
        name: "Login",
        path: "/login",
        element: <Login {...content} />,
      },
      {
        key: "verification",
        name: "Verification",
        path: "/verification",
        element: (
          <Guard>
            <FundingApplicationStepper />
            <Verification {...content} />
          </Guard>
        ),
      },
      {
        key: "funding-application",
        name: "Funding Application",
        path: "/funding-application",
        element: <FundingApplication {...content} />,
      },
      {
        key: "dashboard",
        name: "Dashboard",
        path: "/dashboard",
        element: (
          <Guard>
            <Dashboard {...content} />
          </Guard>
        ),
      },
      {
        key: "tcpa-consent",
        name: "TCPA Consent",
        path: "/tcpa-consent",
        element: (
          // <Guard>
          <TCPAConsentDownloadPage />
          // </Guard>
        ),
      },
      {
        key: "privacy-policy",
        name: "Privacy Policy",
        path: "/privacy-policy",
        element: (
          // <Guard>
          <PrivacyPolicyDownloadPage />
          // </Guard>
        ),
      },
      {
        key: "reset-password",
        name: "Reset Password",
        path: "/reset-password",
        element: (
          // <Guard>
          <PasswordResetPage />
          // </Guard>
        ),
      },
      {
        key: "set-new-password",
        name: "Set New Password",
        path: "/set-new-password",
        element: (
          // <Guard>
          <NewPasswordPage />
          // </Guard>
        ),
      },
      // {
      //   key: "cookies",
      //   name: "Cookies",
      //   path: "/cookies",
      //   element: (
      //     // <Guard>
      //     <Cookies />
      //     // </Guard>
      //   ),
      // },
      {
        key: "blog",
        name: "Blog",
        path: "/blog",
        element: isLoading ? <Loader /> : <Blog />,
      },
      {
        key: "blog-article",
        name: "Blog Article",
        path: "/blog/:slug",
        element: isLoading ? <Loader /> : <BlogArticle />,
      },
    ];
  }, [content]);

  return (
    <Router>
      <NavBar />
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.key}
            exact
            path={route.path}
            element={route.element}
          >
            {route.children}
          </Route>
        ))}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
