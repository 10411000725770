import {isNil} from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AdditionalInfo,
  Documents,
  PsychTest,
  Register,
  SchoolAndProgram,
  Disbursement,
} from ".";
import { Footer, Loader } from "../../components/common";
import { getLoginToken, isLogged } from "../../helpers/authorizationToken";
import { useGetMe, useHash } from "../../hooks";
import FundingApplicationDecorations from "./funding-application-decorations/FundingApplicationDecorations";
import "./FundingApplication.scss";
import FundingApplicationBubbleStepper from "./FundingApplicationBubbleStepper";
import FundingApplicationStepper from "./FundingApplicationStepper";
import { StepsMapping, INFO_STEP, TEST_STEP } from "../../helpers/steps"

const FundingApplication = (props) => {
  const navigate = useNavigate();
  const { data: me, isLoading, isRefetching } = useGetMe();
  const [applicationState, setApplicationState] = useState();
  const [hash, setHash] = useHash();

  const [previewState, setPreviewState] = useState();
  const [isDirectVisit, setIsDirectVisit] = useState(window.location.hash === '');

  const latestApplication = me?.fundingApplications?.[0];
  const latestApplicationState =
    latestApplication?.currentState || "firstStep";

  const setApplicationStateWithPreview = (state, skipPreview = false, skipState = false) => {
    setApplicationState(state)
    // console.log('applicationState', state)
    // console.log('skipPreview', skipPreview)

    if (skipPreview) return
    // console.log('previewState', state)
    setPreviewState(state)
  }

  useEffect(() => {
    document.body.classList.add("funding-application");

    return () => {
      document.body.classList.remove("funding-application");
    };
  }, []);

  useEffect(() => {
    const { fundingApplications, enabled } = me || {};

    if (me && getLoginToken()) {
      if (!enabled) {
        navigate("/verification");
      }

      const latestApplication = fundingApplications?.[0];
      const latestApplicationState =
        latestApplication?.currentState || "firstStep";

      // Redirect to dashboard if application is finished
      if (latestApplicationState === "stepOneFinished") {
        navigate("/dashboard#stepOneFinished");
      }

      setApplicationStateWithPreview(latestApplicationState, true);
    }
  }, [me, getLoginToken()]);

  useEffect(() => {
    if(!applicationState) return

    // how to revert mapping to get step from applicationState
    const stepId = Object.keys(StepsMapping).find(key => StepsMapping[key] === applicationState)

    if (!stepId) return

    // find step index in flow
    // let stepIndex = StepsFlow.findIndex(stepLoop => stepLoop === stepId)
    if (!isDirectVisit) {
      return;
    }

    // console.log('stepId', stepId)

    setHash(stepId)
    //window.history.pushState({}, '', `#${stepId}`);
    setIsDirectVisit(false)
  }, [applicationState])

  useEffect(() => {
    const stepId = Object.keys(StepsMapping).find(key => StepsMapping[key] === previewState)
    if (stepId) {
      setHash(stepId)
      //window.history.pushState({}, '', `#${stepId}`);
    }

  }, [previewState]);

  useEffect(() => {
    let hashTmp = hash;

    // remove # from hash
    if (hash.startsWith('#')) {
      hashTmp = hashTmp.slice(1);
    }

    // find mapping for hash
    let urlState = StepsMapping[hashTmp]
    if (!urlState) return

    // in case it is not in test state... we won't allow to go back to test
    if (applicationState !== 'stepThreeFinished' && hashTmp === TEST_STEP) {
      urlState = StepsMapping[INFO_STEP]
    }

    if (urlState !== previewState) {
      setPreviewState(urlState)
    }
  }, [hash]);

  const isReadonly = applicationState !== previewState;

  // console.log('isReadonly', isReadonly);
  // console.log('previewState2', previewState);
  if (
    isReadonly === false &&
    latestApplicationState === "preApproved"
  ) {
    // navigate("/dashboard#stepOneFinished");
  }

  if (
    latestApplicationState === "softDeclined" ||
    latestApplicationState === "hardDeclined"
  ) {
    navigate("/");
  }

  return isLoading || isRefetching ? (
    <Loader />
  ) : (
    <>
      <main>
        {(!isLogged() ||
          previewState === "register" ||
          previewState === "firstStep" ||
          previewState === "stepOneFinished") && (
          <FundingApplicationStepper
            activeStep={isLogged() ? applicationState : "register"}
          />
        )}
        {isLogged() &&
          !isNil(applicationState) &&
          previewState !== "register" &&
          previewState !== "firstStep" &&
          previewState !== "stepOneFinished" && (
            <FundingApplicationStepper activeStep={latestApplicationState} />
          )}
        <div className="innerWrapper">
          {!isLogged() ? (
            <Register />
          ) : isLogged() ? (
            previewState === "register" ? (
              <Register isReadonly={isReadonly} setPreviewState={setPreviewState} />
            ) :
            previewState === "firstStep" ? (
              <SchoolAndProgram onProceed={isReadonly ? setPreviewState : setApplicationStateWithPreview} isReadonly={isReadonly} latestApplication={latestApplication} />
            ) : previewState === "stepTwoFinished" ? (
              <AdditionalInfo onProceed={isReadonly ? setPreviewState : setApplicationStateWithPreview} isReadonly={isReadonly}/>
            ) : previewState === "stepThreeFinished" ? (
              <PsychTest onProceed={setApplicationStateWithPreview} />
            ) : previewState === "stepFourFinished" ? (
              <Documents onProceed={isReadonly ? setPreviewState : setApplicationStateWithPreview} isReadonly={isReadonly} />
            ) : previewState === "terminated" ? (
              <Disbursement />
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
        {/* This is preventing click on buttons - have to fix CSS */}
        <FundingApplicationDecorations />
      </main>
      <Footer />
    </>
  );
};

export default FundingApplication;
