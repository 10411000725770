import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { Button, Modal } from "..";
import { Stack, Typography } from "@mui/material";
import pdfIcon from "../../../assets/icons/pdf.svg";
import downloadIcon from "../../../assets/icons/download.svg";
import "./DocumentsModal.scss";

const applicationAndSoliciationDisclosureFile =
  process.env.PUBLIC_URL + "/files/YELO_Application_Disclosure.pdf";

const DocumentsModalContent = ({
  documents,
  handleClose,
  handleAccept,
  ...props
}) => {
  const [selected, setSelected] = useState(documents?.[0]);
  const bodyRef = useRef();
  const scrollableContentRef = useRef();
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [error, setError] = useState(false);

  const handleScroll = () => {
    setButtonState();
  };

  const setButtonState = () => {
    setIsScrolledToBottom(
      bodyRef.current.scrollTop + bodyRef.current.clientHeight >=
        scrollableContentRef.current.clientHeight - 20
    );
  };

  useEffect(() => {
    setButtonState();
  }); //, [scrollableContentRef, bodyRef]

  const handleTermAccept = () => {
    const nextDocIndex =
      documents.findIndex((doc) => doc.id === selected.id) + 1;

    if (nextDocIndex !== documents.length) {
      setSelected(documents[nextDocIndex]);
    } else {
      if (clicked) {
        handleAccept();
        handleClose();
      } else {
        setError(true);
      }
    }

    bodyRef.current.scrollTop = 0;
    setButtonState();
  };

  return (
    <div {...props} className="documents-modal">
      <div className="list">
        <div className="inner-wrapper">
          {documents.map((doc) => {
            return (
              <div
                className={`list-element${
                  selected?.id === doc.id ? " selected" : ""
                }`}
              >
                {doc.title}
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex-column align-center justify-center content">
        <h2>{selected?.title}</h2>
        <div className="body" onScroll={handleScroll} ref={bodyRef}>
          <div className="scrollable-content" ref={scrollableContentRef}>
            {selected?.id === "application-disclosure" ? (
              <Stack direction="column" gap={2}>
                <p style={{ textAlign: "center" }}>
                  Please download and review the Application Disclosure
                </p>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "1px solid #ced5ec",
                    borderRadius: "3rem",
                    padding: "2rem",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <img src={pdfIcon} alt="" />
                    <a
                      href={applicationAndSoliciationDisclosureFile}
                      download
                      style={{
                        textDecoration: "none",
                        color: "#203864",
                        pointerEvents: "none",
                      }}
                      id="download"
                      onClick={() => setClicked(true)}
                    >
                      Application Disclosure
                    </a>
                  </Stack>
                  <img
                    src={downloadIcon}
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      document.getElementById("download").click();
                      setClicked(true);
                    }}
                  />
                </Stack>
                {!clicked && error && (
                  <div
                    style={{
                      fontSize: "1.4rem",
                      color: "#fd746b",
                      marginLeft: "2rem",
                    }}
                  >
                    Please download and review the file
                  </div>
                )}
              </Stack>
            ) : (
              selected?.text
            )}
          </div>
        </div>
        <Button
          className="primary"
          label={
            selected?.id === documents?.length
              ? "Accept & Finish"
              : "Accept & Continue"
          }
          onClick={(e) => {
            e.preventDefault();
            handleTermAccept();
          }}
          disabled={!isScrolledToBottom || (!clicked && error)}
        />
      </div>
    </div>
  );
};

export default DocumentsModalContent;
