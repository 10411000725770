import React from "react";
import facebookLogo from "../../../assets/images/facebook.svg";
import instagramLogo from "../../../assets/images/instagram.svg";
import linkedinLogo from "../../../assets/images/linkedin.svg";
import tiktokLogo from "../../../assets/images/tiktok.svg";
import twitterLogo from "../../../assets/images/x.svg";
import youtubeLogo from "../../../assets/images/youtube.svg";
import snapchatLogo from "../../../assets/images/snapchat.svg";
import { useGetHomePageContent } from "../../../hooks";
import "./Footer.scss";

const Footer = ({ condensed = false }) => {
  const { data } = useGetHomePageContent();
  const {
    contactUsAddress: address,
    phone,
    contactUsEmail: email,
    facebookLink,
    instagramLink,
    linkedInLink,
    tiktokLink,
    twitterLink,
    youtubeLink,
    snapchatLink,
  } = data || {};

  const accessibilityDisclosureFile =
    process.env.PUBLIC_URL + "/files/YELO_Accessibility_Disclosure.pdf";
  const privacyPolicyFile =
    process.env.PUBLIC_URL + "/files/YELO_Privacy_Policy.pdf";
  const socialMediaPolicyFile =
    process.env.PUBLIC_URL + "/files/YELO_Social_Media_Policy.pdf";
  const termsAndConditionsFile =
    process.env.PUBLIC_URL + "/files/YELO_Terms_and_Conditions.docx";

  return !condensed ? (
    <footer className="siteFooter">
      <div className="details">
        <article className="contactUs">
          <p dangerouslySetInnerHTML={{ __html: address }}></p>
          <p>{phone}</p>
          <p>
            <a href={`mailto:${email}`}>{email}</a>
          </p>
        </article>
        <article className="resources">
          {/* <h3>Resources</h3> */}
          <ul>
            <li>
              <a href={accessibilityDisclosureFile} download>
                Accesssibility Disclosure
              </a>
            </li>
            <li>
              <a href={socialMediaPolicyFile} download>
                Social Media Policy
              </a>
            </li>
            <li>
              <a href={termsAndConditionsFile} download>
                Terms & Conditions
              </a>
            </li>
            <li>
              <a href={privacyPolicyFile} download>
                Privacy Policy
              </a>
            </li>
          </ul>
        </article>
        <article className="social">
          <ul>
            <li>
              <a href={facebookLink} target="_blank" rel="noreferrer">
                <img src={facebookLogo} alt="facebook" />
              </a>
            </li>
            <li>
              <a href={linkedInLink} target="_blank" rel="noreferrer">
                <img src={linkedinLogo} alt="linkedin" />
              </a>
            </li>
            <li>
              <a href={instagramLink} target="_blank" rel="noreferrer">
                <img src={instagramLogo} alt="instagram" />
              </a>
            </li>
            <li>
              <a href={tiktokLink} target="_blank" rel="noreferrer">
                <img src={tiktokLogo} alt="tiktok" />
              </a>
            </li>
            <li>
              <a href={twitterLink} target="_blank" rel="noreferrer">
                <img src={twitterLogo} alt="twitter" />
              </a>
            </li>
            <li>
              <a href={youtubeLink} target="_blank" rel="noreferrer">
                <img src={youtubeLogo} alt="youtube" />
              </a>
            </li>
            <li>
              <a href={snapchatLink} target="_blank" rel="noreferrer">
                <img src={snapchatLogo} alt="snapchat" />
              </a>
            </li>
          </ul>
        </article>
      </div>
      <div className="bar">
        <span className="copyright">
          &copy; 2022-24 YELO Funding, Inc. All Rights Reserved.
        </span>
        {/* <a href="#">Terms &amp; conditions</a> */}
      </div>
    </footer>
  ) : (
    <footer className="siteFooter condensed">
      <div className="bar">
        <span className="copyright">
          &copy; 2022-24 YELO Funding, Inc. All Rights Reserved.
        </span>
        <article className="resources">
          <ul>
            <li>
              <a href={accessibilityDisclosureFile} download>
                Accessibility Disclosure
              </a>
            </li>
            <li>
              <a href={socialMediaPolicyFile} download>
                Social Media Policy
              </a>
            </li>
            <li>
              <a href={termsAndConditionsFile} download>
                Terms & Conditions
              </a>
            </li>
            <li>
              <a href={privacyPolicyFile} download>
                Privacy Policy
              </a>
            </li>
          </ul>
        </article>
        <ul className="social">
          <li>
            <a href={facebookLink} target="_blank" rel="noreferrer">
              <img src={facebookLogo} alt="facebook" />
            </a>
          </li>
          <li>
            <a href={linkedInLink} target="_blank" rel="noreferrer">
              <img src={linkedinLogo} alt="linkedin" />
            </a>
          </li>
          <li>
            <a href={instagramLink} target="_blank" rel="noreferrer">
              <img src={instagramLogo} alt="instagram" />
            </a>
          </li>
          <li>
            <a href={tiktokLink} target="_blank" rel="noreferrer">
              <img src={tiktokLogo} alt="tiktok" />
            </a>
          </li>
          <li>
            <a href={twitterLink} target="_blank" rel="noreferrer">
              <img src={twitterLogo} alt="X" />
            </a>
          </li>
          <li>
            <a href={youtubeLink} target="_blank" rel="noreferrer">
              <img src={youtubeLogo} alt="youtube" />
            </a>
          </li>
          <li>
            <a href={snapchatLink} target="_blank" rel="noreferrer">
              <img src={snapchatLogo} alt="snapchat" />
            </a>
          </li>
        </ul>
        {/* <a className="terms-and-conditions" href="#">
          Terms &amp; conditions
        </a> */}
      </div>
    </footer>
  );
};

export default Footer;
