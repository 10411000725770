import React, { useEffect, useRef, useState } from "react";
import { useIsInViewport, useWindowSize } from "../../../hooks";
import "./ImportantPoints.scss";

const ImportantPoints = ({ points = [] }) => {
  const windowSize = useWindowSize();
  const isInViewportThreshold = windowSize.width > 768 ? 0.5 : 0.2;
  const sectionRef = useRef(null);
  const isInViewport = useIsInViewport(sectionRef, isInViewportThreshold);
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    if (isInViewport) {
      setTimeout(() => setShowAnimation(isInViewport), 50);
    }
  }, [isInViewport]);

  return (
    <section
      className={`section section--importantPoints ${
        showAnimation ? "onScreen" : ""
      }`}
      ref={sectionRef}
    >
      <div className="section__innerWrapper">
        <h1 style={{color: '#FFFFFF', textAlign: 'center', marginBottom: '8px', fontSize: 'clamp(28px, 4vw, 32px)'}}>College Funding Done Right</h1>
        <p className="points-header">
          A better, smarter way to finance your education
        </p>
        <ul>
          {points?.map((point, i) => (
            <li key={`${i}_${point.img}`} className="animateIn overDeco">
              <i className="icon icon--income-driven">
                <img
                  src={`${process.env.REACT_APP_IMG_URL}/${point.img}`}
                  alt=""
                  height={35}
                />
              </i>
              <h3>{point.title}</h3>
              <p>{point.short}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default ImportantPoints;
