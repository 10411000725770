import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ReactCodeInput from "react-code-input";
import { useNavigate } from "react-router-dom";
import {
  requestVerificationCode,
  verifyVerificationCode,
} from "../../../api/security";
import { Footer, LoaderIndicator } from "../../../components/common";
import { useGetMe } from "../../../hooks";
import FundingApplicationDecorations from "../../funding-application/funding-application-decorations/FundingApplicationDecorations";
import "./Verification.scss";

const CodeInputForm = ({ setCode }) => {
  const [isSending, setIsSending] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    document.body.classList.add("funding-application");
    document.body.classList.add("verification");

    return () => {
      document.body.classList.remove("funding-application");
      document.body.classList.remove("verification");
    };
  }, []);

  return (
    <>
      <ReactCodeInput
        type="number"
        fields={6}
        onChange={(val) => setCode(val)}
        className="verification-code-input"
      />
      <div className="new-code-container">
        <p>
          No SMS with vertification code?&nbsp;
          <span
            className="link"
            style={isSending ? { color: "grey", textDecoration: "none" } : {}}
            onClick={async () => {
              setIsSending(true);
              try {
                await requestVerificationCode().then(() => {
                  enqueueSnackbar("Code requested successfully", {
                    variant: "success",
                  });
                });
              } catch (e) {
                console.log("Error: ", e);
              }
              setIsSending(false);
            }}
          >
            {isSending ? "Sending..." : "Send new code"}
          </span>
        </p>
      </div>
    </>
  );
};

const Verification = ({
  contactUsAddress,
  contactUsPhone,
  contactUsEmail,
  facebookLink,
  instagramLink,
  tiktokLink,
  youtubeLink,
  snapchatLink,
  onProceed,
}) => {
  const [code, setCode] = useState();
  const { refetch, isRefetching, data } = useGetMe();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  if(data?.enabled) {
    navigate("/funding-application#firstStep");
  }

  useEffect(() => {
    if (code?.length === 6) {
      setLoading(true);
      (async () => {
        await verifyVerificationCode({
          verificationCode: parseInt(code),
        }).then(async (res) => {
          await refetch();
          if (res.data.enabled === true) {
            navigate("/funding-application#firstStep");
          }
        });
      })();
      setLoading(false);
    }
  }, [code]);

  useEffect(() => {
    requestVerificationCode();
  }, []);

  return (
    <>
      <main className="verification">
        <div
          className="flex-column align-center content"
          style={{ gap: "2rem" }}
        >
          <div style={{ fontSize: "24px", marginTop: "4rem" }}>
            Profile Info Confirmation
          </div>
          <div style={{ maxWidth: 500, textAlign: "center" }}>
            We send you a confirmation code to the phone number provided by you.
            Input the 6-digit code below.
          </div>
          {loading || isRefetching ? (
            <LoaderIndicator />
          ) : (
            <CodeInputForm setCode={setCode} />
          )}
        </div>
        <FundingApplicationDecorations />
      </main>
      <Footer />
    </>
  );
};

export default Verification;
